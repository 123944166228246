import React, { useEffect, useState } from 'react'
import styles from '../../../styles/Admission/admissionPortal.module.css';
import { Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

import BSMWhiteTransparent from '../../../files/BSM Logo blanco fondo transparente.png';
import { useDispatch } from 'react-redux';
import { setAuthSession } from '../../../app/features/Session/sessionSlice';
import Cookies from 'universal-cookie';
import BeatLoader from "react-spinners/BeatLoader";

export default function Admision() {

  // Load Applicants if exist 
  useEffect(() => {

    // loadAplicants

  },[])

  const dispatch = useDispatch();
  const cookies = new Cookies();



  const navigate = useNavigate();
  const [flagError,setFlagError] = useState(false);
  const [loading,SetLoading] = useState(false);

  const [credentials,setCredentials] = useState({
    email :'',
    password:''
  });

  const handleChange = (e) => {

    setCredentials({
      ...credentials,
      [e.target.name] : e.target.value
    });

  }
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleSubmit = async () => {
    SetLoading(true);
  //   if(credentials.email == 'admin@localhost.com' && credentials.password == 'password'){
  //     navigate('/AdmissionPortal/Landing')
  //   } else {
  //        setFlagError(true);
  // //  console.log('error',error);
  //   }
    try {
     const response =  await axios.post('https://xtendapi.boardingschools.mx/api/login',credentials)
     if(response.status == 200){
      // let expires = new Date()
      // expires.setTime(expires.getTime() + (response.data.expires_in * 1000))
      dispatch ( setAuthSession(response.data.data ,'Login') )
      cookies.set('token',response.data.data.token, {path: '/', expires: new Date(Date.now()+2592000)});
      cookies.set('UserLogged',response.data.data, {path: '/', expires: new Date(Date.now()+2592000)});

      await delay(2000);
      SetLoading(false);
      navigate('/AdmissionPortal/Landing')
        // router.push('/AdmissionPortal/Landing');
     }

    } catch (error) {
      SetLoading(false);
     setFlagError(true);
     console.log('error',error);

    }
  }

  return (
    
    // Box father
    
    <div>
      
      {/* <div className={`${styles.item} ${styles.item1}`}>Item 1 </div> */}
      
      <div className={styles.workArea}>
      <div className={styles.boxBannerBSM}>
     <center>
       <img className={styles.logoBSMWhite} src={BSMWhiteTransparent} alt="logo" />
      </center>
      </div>

        {/* div of content */}
        <div className={styles.content}>
          {/* Title  */}

     

          <div className={styles.title}>
            <h1>Boarding Schools MX</h1>
          </div>
          

          {/* Dinamic inputs */}
          <div>
        {flagError &&
        <div className={styles.errorBox}>
        <span >Incorrect Username or password</span>
        </div>
        }
        {
          loading &&
           <div style={{ display:'flex',textAlign:'center',justifyContent:'center'}}> 
        <BeatLoader color='#395a8b' size={10}/>
        </div>
        }

         {/* Forms  */}
          {/* Username */}
          <div className={styles.formLogin}>
            <label className={styles.labelText}> Email </label>
            <input className={styles.inputText} type="email" name="email" onChange={handleChange} placeholder="user@email.com"/>
          </div>
          {/* Passwords  */}
          <div className={styles.formLogin}>
            <label className={styles.labelText}> password </label>
            <input className={styles.inputText}  name="password" type='password' onChange={handleChange} placeholder="******"/>
        
          </div>

          {/* Button Login */}
          <div className={styles.buttonBox}>
            <button onClick={handleSubmit} type="button" className={styles.buttonLogin}>Log In</button>
          </div>



    </div>

          <div className={styles.footerBox}>
          <p>

          <Link to="https://boardingschools.mx/" target="_blank">
          Boarding Schools MX © 2024  &nbsp;
          </Link>
          • &nbsp;
          <Link to="https://boardingschools.mx/aviso-de-privacidad/" target="_blank">
          Privacy Policy  &nbsp;
          </Link>
           
          </p>
          </div>
        </div>


      </div>
   
      {/* <div className={styles.banner}>
        <div className={styles.bannerArea}>
          <img className={styles.logoBanner} src={logoTest} alt="logo" width={100} height={100} />

        </div>

      </div> */}

    </div>
  )
}
